import { signOut } from "../services/firebase";
import { Container, Typography, Link, Box } from "@mui/material";
export const SignOutPage = () => {
  signOut();
  return (
    <Container maxWidth="sm">
      <Box textAlign={"center"}>
        <img
          src="./images/please-sign-in.png"
          alt="please sign in"
          style={{ height: "30vh" }}
        />
      </Box>
      <Typography variant="h6" align="center">
        You have been signed out.
      </Typography>
      <Typography variant="h6" align="center">
        <Link href="/signin">Sign In </Link>
      </Typography>
    </Container>
  );
};
