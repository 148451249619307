import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppContext } from "../context/AppContext";
import { CartButton } from "./CartButton";
import { AccountMenu } from "./AccountMenu";

export const ClubHeader = () => {
  const { clubInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const navigateToHome = () => navigate("/");
  return (
    <AppBar position="fixed" enableColorOnDark>
      <Container maxWidth={"md"}>
        <Toolbar disableGutters>
          <Box
            onClick={navigateToHome}
            sx={{
              p: 0.5,
              mr: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: 100,
              backgroundColor: "rgba(248,255,246, 0.3)",
              boxShadow: "0px 0px 89px 15px rgba(248,255,246,0.76);",
            }}
          >
            <img
              src="/logo.svg"
              alt={clubInfo?.address.name}
              width={50}
              height={50}
            />
          </Box>
          <Typography
            variant="p"
            noWrap
            component={"a"}
            onClick={navigateToHome}
            color={"white"}
            sx={{
              mr: 2,
              flexGrow: 1,
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            {" "}
          </Typography>
          <CartButton />
          <AccountMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
