import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import { sharePass } from "../services/firebase";
import ShareIcon from "@mui/icons-material/Share";
import { Loading } from "./Loading";
export const SharePassButton = ({ pass }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleShare = async () => {
    setError("");
    setLoading(true);
    try {
      const share = await sharePass(pass.id, email);
      setEmail("");
      if (share.error) {
        setError(share.message);
      }
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setOpen(false);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Loading isLoading={loading} message="Sharing Pass" />
      <Box>
        <Button variant="outlined" onClick={() => setOpen(true)}>
          <ShareIcon sx={{ mr: 2 }} />
          Share
        </Button>
        {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ background: "#FFFFFF" }}>
          <DialogTitle>Share this {pass.type} pass</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              To share this pass with a friend, enter their email address below.
              Note: You will not be able to check in with this pass after
              sharing it.
            </DialogContentText>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={handleShare}
              disabled={loading}
            >
              Share
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};
