import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { EventCard } from "../components/EventCard";
import { getEvents } from "../services/firebase";

export const EventsList = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    getEvents().then((events) => {
      if (events && !events.error) {
        setEvents(events);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>Sorry, we couldn't find any events.</p>;
  }

  return (
    <Grid container spacing={2}>
      {events.map((event) => (
        <Grid key={event.id} item xs={12} sm={6} md={4}>
          <EventCard event={event} />
        </Grid>
      ))}
    </Grid>
  );
};
