import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import { AppContext } from "../context/AppContext";
import { CartContext } from "../context/CartContext";
import { DiscountCode } from "../components/DiscountCode";
import { Cart } from "../components/Cart";
import { ShippingAddressForm } from "../components/ShippingAddressForm";
import { Loading } from "../components/Loading";

const base_url = "https://us-central1-manageyourclub-973b2.cloudfunctions.net";
const location_id = "LSE0029KQMC9T";
const application_id = "sq0idp-Z9rpS42Bx5ZcL--MM4CJ_w";

const calculateDiscount = (total, discount) => {
  if (!discount) {
    return 0;
  }
  if (discount.percentOff) {
    return total * (discount.percentOff / 100);
  }
  // don't allow a negative total
  return Math.min(discount.amountOff, total);
};

export const Checkout = () => {
  const { member, setOrder, clubId, isAuthPending } = useContext(AppContext);
  const { cartItems, getCartTotal, discount, clearCart } =
    useContext(CartContext);
  const [error, setError] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);

  const updateShippingAddress = (address) => {
    setShippingAddress(address);
    setShowShippingAddressForm(false);
  };

  const cancelSetShippingAddress = () => {
    setShippingAddress(null);
    setShowShippingAddressForm(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthPending && !member) {
      localStorage.setItem("redirectAfterLogin", `/checkout`);
    }
  }, [member, isAuthPending]);

  const total = getCartTotal();

  const discountAmount = calculateDiscount(total, discount);

  const totalWithDiscount = total - discountAmount;

  const createPaymentRequest = () => ({
    countryCode: "US",
    currencyCode: "USD",
    total: {
      amount: (totalWithDiscount / 100).toString(),
      label: "Total",
    },
    lineItems: cartItems,
    requestBillingContact: true,
    requestShippingContact: false,
  });

  const handlePayment = async (token) => {
    setError(null);
    setProcessingPayment(true);
    try {
      const response = await fetch(`${base_url}/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId: member.id,
          clubId,
          lineItems: cartItems,
          discount,
          total: totalWithDiscount,
          currency: "USD",
          sourceId: token,
          shippingAddress: shippingAddress || null,
        }),
      });
      if (response.ok) {
        const order = await response.json();
        setOrder(order);
        clearCart();
        setProcessingPayment(false);
        navigate("/thank-you");
      } else {
        const errorMessage = await response.text();
        setError(`Payment failed: ${errorMessage}`);
        setProcessingPayment(false);
      }
    } catch (e) {
      setError("There was an error processing your payment");
      setProcessingPayment(false);
      return;
    }
  };

  if (!isAuthPending && !member) {
    return (
      <Container
        sx={{ pt: 4, display: "flex", justifyContent: "space-between" }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" color={"error"} gutterBottom>
            You must be logged in to purchase.
          </Typography>
          <Link to="/signin">Sign In</Link>
        </Box>
        <Box
          component={"img"}
          src="./images/please-sign-in.png"
          alt="please sign in"
          sx={{ height: "30vh" }}
        />
      </Container>
    );
  }

  return (
    <Container sx={{ pt: 1 }}>
      <Loading
        isLoading={processingPayment}
        message="Processing your payment..."
        messages={[
          "Processing your payment...",
          "Biting the coins...",
          "Holding up to the light...",
          "Calling the bank...",
          "Filling out form #Y-420",
          "Arguing with a computer...",
        ]}
      />
      <Typography variant="h5" gutterBottom>
        Checkout
      </Typography>
      <Cart />
      <Box sx={{ textAlign: "right" }}>
        <DiscountCode />
      </Box>
      <Box>
        {showShippingAddressForm ? (
          <ShippingAddressForm
            initialAddress={shippingAddress}
            cancel={cancelSetShippingAddress}
            setShippingAddress={updateShippingAddress}
          />
        ) : (
          <Button onClick={() => setShowShippingAddressForm(true)}>
            {!shippingAddress
              ? "Add a Shipping Address"
              : "Change Shipping Address"}
          </Button>
        )}
        {shippingAddress && (
          <Box
            sx={{
              mt: 2,
              mx: 2,
              display: "flex",
              alignItems: "flex-start",
              boxShadow: 1,
              py: 2,
            }}
          >
            <IconButton color="error">
              <Delete onClick={() => setShippingAddress(null)} />
            </IconButton>
            <Box sx={{ px: 2 }}>
              <Typography variant="body1" gutterBottom>
                {shippingAddress.name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {shippingAddress.address}
                <br />
                {shippingAddress.city}, {shippingAddress.state}{" "}
                {shippingAddress.zip}
                <br />
                {shippingAddress.country}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {error && (
        <Typography variant="h5" color={"error"} gutterBottom>
          {error}
        </Typography>
      )}

      <PaymentForm
        applicationId={application_id}
        cardTokenizeResponseReceived={(token, verifiedBuyer) => {
          handlePayment(token);
        }}
        createPaymentRequest={createPaymentRequest}
        locationId={location_id}
      >
        <Container sx={{ my: 4 }}>
          <CreditCard
            buttonProps={{
              css: {
                backgroundColor: "#2c7e3f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#569765",
                },
              },
            }}
          />
          <Box sx={{ my: 2 }} />
        </Container>
      </PaymentForm>
    </Container>
  );
};
