import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );
  const [discount, setDiscount] = useState(
    localStorage.getItem("discount")
      ? JSON.parse(localStorage.getItem("discount"))
      : null
  );

  const addToCart = (item, selectedOptions = []) => {
    if (selectedOptions.length > 0) {
      const optionsPart =
        selectedOptions.length > 0 ? `-${selectedOptions.join("-")}` : "";
      const sku = `${item.id}${optionsPart}`;
      item.sku = sku;
      item.selectedOptions = selectedOptions;
    }
    if (!item.sku) {
      item.sku = item.id;
    }

    const isItemInCart = cartItems.find(
      (cartItem) => cartItem.sku === item.sku
    );
    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.sku === item.sku
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems([
        ...cartItems,
        { ...item, quantity: item.quantity ? item.quantity : 1 },
      ]);
    }
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find(
      (cartItem) => cartItem.sku === item.sku
    );

    if (isItemInCart.quantity === 1) {
      setCartItems(cartItems.filter((cartItem) => cartItem.sku !== item.sku));
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.sku === item.sku
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartDiscount = () => {
    if (!discount) {
      return 0;
    }
    if (discount.percentOff) {
      return getCartSubtotal() * (discount.percentOff / 100);
    }
    return discount.amountOff;
  };

  const getCartSubtotal = () => {
    return cartItems.reduce((total, item) => {
      const discountedPrice = getDiscountedPrice(item, item.quantity);
      return total + discountedPrice * item.quantity;
    }, 0);
  };

  const getCartTotal = () => {
    return Math.max(getCartSubtotal() - getCartDiscount(), 0);
  };

  const getNumberOfItemsInCart = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const getDiscountedPrice = (item, quantity) => {
    if (!item.quantityDiscount) {
      return item.amount;
    }
    let price = item.amount;
    Object.keys(item.quantityDiscount).forEach((qty) => {
      if (quantity >= qty) {
        price = item.quantityDiscount[qty];
      }
    });
    return price;
  };

  const getCartItems = () => {
    return cartItems.map((item) => {
      const discountedAmount = getDiscountedPrice(item, item.quantity);
      return {
        ...item,
        discountedAmount,
      };
    });
  };

  useEffect(() => {
    const localCartItems = localStorage.getItem("cartItems");
    if (localCartItems) {
      setCartItems(JSON.parse(localCartItems));
    }
    const localDiscount = localStorage.getItem("discount");
    if (localDiscount) {
      setDiscount(JSON.parse(localDiscount));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);
  useEffect(() => {
    localStorage.setItem("discount", JSON.stringify(discount));
  }, [discount]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        getCartItems,
        addToCart,
        removeFromCart,
        clearCart,
        getCartSubtotal,
        getCartTotal,
        getNumberOfItemsInCart,
        discount,
        setDiscount,
        getCartDiscount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
