import { createContext, useEffect, useState } from "react";
import {
  auth,
  db,
  getOrCreateMember,
  getClubInfo,
  getClubPassTemplates,
  CLUB_ID,
  getClubProducts,
} from "../services/firebase";

const passStatusOrder = {
  active: 1,
  pending: 2,
  shared: 3,
  expired: 4,
};

export const AppContext = createContext({
  clubId: CLUB_ID,
  auth: null,
  user: null,
  member: null,
  memberPasses: [],
  passTemplates: [],
  selectedPass: null,
  order: null,
  setSelectedPass: () => {},
  setOrder: () => {},
  setDiscount: () => {},
});

export const AppProvider = ({ children }) => {
  const [isAuthPending, setIsAuthPending] = useState(true); // auth pending
  const [user, setUser] = useState(null); // auth user
  const [member, setMember] = useState(null); // club member
  const [memberPasses, setMemberPasses] = useState([]);

  const [checkedInTime, setCheckedInTime] = useState(
    localStorage.getItem("checkedInTime")
      ? new Date(JSON.parse(localStorage.getItem("checkedInTime")))
      : null
  );

  const onCheckIn = () => {
    // Set the timestamp to 4 hours from now
    const futureTime = new Date();
    futureTime.setHours(futureTime.getHours() + 4);
    localStorage.setItem("checkedInTime", JSON.stringify(futureTime));
    setCheckedInTime(futureTime);
  };

  const isCheckedIn = () => {
    return checkedInTime && new Date() < new Date(checkedInTime);
  };

  const [order, setOrder] = useState(
    localStorage.getItem("order")
      ? JSON.parse(localStorage.getItem("order"))
      : null
  );
  const [discount, setDiscount] = useState(null);
  // club info
  const [clubInfo, setClubInfo] = useState(null);
  const [passTemplates, setPassTemplates] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(order));
  }, [order]);

  useEffect(() => {
    localStorage.setItem("products", JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    getClubPassTemplates().then((templates) => {
      setPassTemplates(templates);
    });

    getClubInfo().then((info) => {
      setClubInfo(info);
    });

    getClubProducts().then((products) => {
      setProducts(products);
    });
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((signedInUser) => {
      if (signedInUser) {
        setUser(signedInUser);
      } else {
        setUser(null);
        setMember(null);
        setOrder(null);
        setDiscount(null);
        setMemberPasses([]);
        setCheckedInTime(null);
        localStorage.setItem("checkedInTime", null);
        localStorage.setItem("order", null);
      }
      setIsAuthPending(false);
    });
    return unregisterAuthObserver;
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    let unsubscribe = () => {};

    const setupMemberSubscription = async () => {
      try {
        const memberRef = await getOrCreateMember(user);

        unsubscribe = memberRef.onSnapshot((docSnapshot) => {
          if (docSnapshot.exists) {
            const updatedMember = {
              ...docSnapshot.data(),
              id: docSnapshot.id,
            };
            setMember(updatedMember);
          } else {
            // Handle the case where the document still does not exist
            console.log("Member document does not exist after creation.");
            return null;
          }
        });
      } catch (error) {
        console.error("Error in member subscription setup:", error);
      }
    };

    setupMemberSubscription();

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = db
      .collection(`clubs/${CLUB_ID}/passes`)
      .where("memberId", "==", user.uid)
      .onSnapshot((snapshot) => {
        const updatedPasses = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => {
            return passStatusOrder[a.status] - passStatusOrder[b.status];
          });
        setMemberPasses(updatedPasses);
      });

    return () => unsubscribe();
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        isAuthPending,
        clubId: CLUB_ID,
        clubInfo,
        user,
        memberPasses,
        member,
        setMember,
        order,
        setOrder,
        passTemplates,
        discount,
        setDiscount,
        products,
        checkedInTime,
        onCheckIn,
        isCheckedIn,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
