import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export const EventCard = ({ event }) => {
  return (
    <Paper
      sx={{
        p: 2,
        mt: 2,
        mb: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={3} item>
          <Box
            component="img"
            src={event.image}
            alt={event.name}
            sx={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h3">{event.name}</Typography>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="body1">{event.description}</Typography>
            <Typography variant="body1">
              {event.date} at {event.time}
            </Typography>
            <Typography variant="body1">{event.location.name} </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
