import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getEvent, centsToCurrency } from "../services/firebase";
import { CartContext } from "../context/CartContext";
import { QuantitySelector } from "../components/QuantitySelector";

export const EventDetail = () => {
  const { eventSlug } = useParams();
  const navigate = useNavigate();
  const { addToCart, removeFromCart, getCartItems } = useContext(CartContext);

  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    getEvent(eventSlug).then((event) => {
      if (event && !event.error) {
        console.log(event);
        setEvent(event);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [eventSlug]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>Sorry, we couldn't find that event.</p>;
  }

  return (
    <Paper
      sx={{
        p: 2,
        mt: 2,
        mb: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={3} item>
          <Box
            component="img"
            src={event.image}
            alt={event.name}
            sx={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h3">{event.name}</Typography>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="subtitle1">
              {event.startDate.toDate().toLocaleDateString()} at{" "}
              {event.startDate.toDate().toLocaleTimeString()} until{" "}
              {event.endDate.toDate().toLocaleTimeString()}
            </Typography>
            <Typography variant="body2">
              {event.location.name}
              <br />
              {event.location.address}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography variant="body1">{event.description}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Box sx={{ p: 2, bgcolor: "grey.200" }}>
            <Typography variant="h5">Tickets</Typography>
            {event.tickets.map((ticket) => (
              <Box
                key={ticket.sku}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box sx={{ flexGrow: 1, mr: 1 }}>
                  <Typography variant="h6">{ticket.name}</Typography>
                  <Typography variant="body2">{ticket.description}</Typography>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Typography variant="body1">
                    {centsToCurrency(ticket.amount)}
                  </Typography>
                </Box>
                <QuantitySelector
                  item={
                    getCartItems().find((item) => item.sku === ticket.sku) || {
                      ...ticket,
                      quantity: 0,
                    }
                  }
                  remove={() => removeFromCart(ticket)}
                  add={() => addToCart(ticket)}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 1,
              p: 2,
            }}
          >
            <Typography variant="body1" mr={3}>
              Terms and Conditions
            </Typography>
            <Typography variant="body1" color="success">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/checkout")}
              >
                Checkout
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
