import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

import config from "../firebaseConfig";

firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
const functions = getFunctions();

export const CLUB_ID = "demo";

export const getMemberPasses = async (userId) => {
  try {
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const passes = await db
      .collection(`clubs/${CLUB_ID}/passes`)
      .where("memberId", "==", userId)
      .get();

    const passesData = passes.docs.map((pass) => ({
      id: pass.id,
      currentPass: pass.data().dateExpired > now,
      ...pass.data(),
    }));

    return passesData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};
export const getPass = async (passId) => {
  try {
    const passRef = db.collection(`clubs/${CLUB_ID}/passes`).doc(passId);
    const pass = await passRef.get();
    return pass.data();
  } catch (error) {
    console.error({ error });
    return {};
  }
};

export const updateProfile = async (member, updates) => {
  try {
    const memberRef = db.collection(`clubs/${CLUB_ID}/members`).doc(member.id);
    await memberRef.update({
      ...updates,
      lastupdate: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    const updatedMember = await memberRef.get();
    return updatedMember.data();
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getMemberOrders = async (memberId) => {
  try {
    const orders = await db
      .collection(`clubs/${CLUB_ID}/orders`)
      .where("memberId", "==", memberId)
      .get();
    const orderData = orders.docs
      .map((order) => ({
        id: order.id,
        ...order.data(),
      }))
      .sort((a, b) => {
        return a.createdate < b.createdate ? 1 : -1;
      });
    return orderData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

export const getMember = async (user) => {
  try {
    const memberRef = db.collection(`clubs/${CLUB_ID}/members`).doc(user.uid);
    const memberSnapshot = await memberRef.get();

    if (!memberSnapshot.exists) {
      await memberRef.set({
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        phoneNumber: user.phoneNumber,
        emailVerified: user.emailVerified,
        emailOptIn: false,
        waiverSigned: false,
        lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
        createdate: firebase.firestore.Timestamp.fromDate(new Date()),
        lastupdate: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    } else {
      await memberRef.update({
        lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    }
    const updatedMember = await memberRef.get();
    return updatedMember.data();
  } catch (error) {
    console.error({ error });
  }
};

export const getOrCreateMember = async (user) => {
  const memberRef = db.collection(`clubs/${CLUB_ID}/members`).doc(user.uid);

  const memberSnapshot = await memberRef.get();
  if (!memberSnapshot.exists) {
    await memberRef.set({
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      emailVerified: user.emailVerified,
      emailOptIn: false,
      waiverSigned: false,
      lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
      createdate: firebase.firestore.Timestamp.fromDate(new Date()),
      lastupdate: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  } else {
    await memberRef.update({
      lastLogin: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  }

  return memberRef;
};
const createInstanceIdentifier = (instance) => {
  const startDate = new Date(instance.startDate.seconds * 1000);
  return `${startDate.getFullYear()}-${
    startDate.getMonth() + 1
  }-${startDate.getDate()}`;
};

const generateTicketSKU = (ticket, instance) => {
  const instanceIdentifier = createInstanceIdentifier(instance);
  return `${ticket.id}-${instanceIdentifier}`;
};

export const getEvent = async (eventSlug) => {
  try {
    const eventRef = db
      .collection(`clubs/${CLUB_ID}/events`)
      .where("slug", "==", eventSlug);
    const eventSnapshot = await eventRef.get();

    if (!eventSnapshot.docs[0].exists) {
      console.error(`Event with slug '${eventSlug}' not found.`);
      return { error: true, message: "Event not found" };
    }

    const eventData = eventSnapshot.docs[0].data();

    // Process instances, if they exist
    if (eventData.instances && Array.isArray(eventData.instances)) {
      // find the next future instance
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      eventData.nextInstance = eventData.instances.find(
        (instance) => instance.startDate > now
      );
      if (!eventData.nextInstance) {
        eventData.nextInstance = eventData.instances[0];
      }
      // set the eventStartDate to the first instance
      eventData.startDate = eventData.nextInstance.startDate;
      eventData.endDate = eventData.nextInstance.endDate;
    } else {
      console.error("No instances found for the event.");
      eventData.instances = []; // Handle events without instances
    }
    if (eventData.nextInstance && eventData.tickets) {
      eventData.tickets = eventData.tickets.map((ticket) => {
        return {
          ...ticket,
          sku: generateTicketSKU(ticket, eventData.nextInstance),
          eventId: eventData.slug,
          eventName: eventData.name,
          startDate: eventData.nextInstance.startDate,
          endDate: eventData.nextInstance.endDate,
        };
      });
    }

    return eventData;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getEvents = async () => {
  try {
    const eventsRef = db.collection(`clubs/${CLUB_ID}/events`);
    const events = await eventsRef.get();

    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const eventData = events.docs
      .map((event) => ({
        id: event.id,
        ...event.data(),
      }))
      .filter((event) => {
        if (!event.instances || !Array.isArray(event.instances)) {
          return false;
        }
        return event.instances.some((instance) => instance.startDate > now);
      });

    return eventData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

export const getDiscount = async (code) => {
  try {
    const discounts = await db
      .collection(`clubs/${CLUB_ID}/config/v1/discounts`)
      .where("code", "==", code.toLowerCase())
      .get();
    const discount = discounts.docs[0];
    if (!discount.exists) {
      return { error: true, message: "Invalid Discount Code" };
    }
    return discount.data();
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const getClubInfo = async () => {
  try {
    const clubCollection = db.collection(`clubs/${CLUB_ID}/config/v1/info`);
    const address = await clubCollection.doc("address").get();
    return {
      address: address.data(),
    };
  } catch (error) {
    console.error({ error });
  }
};

export const getClubProducts = async () => {
  try {
    const productRef = db.collection(`clubs/${CLUB_ID}/config/v1/products`);
    const products = await productRef.get();
    const productData = products.docs.reduce((acc, product) => {
      const { category } = product.data();
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({
        id: product.id,
        ...product.data(),
      });
      return acc;
    }, {});
    return productData;
  } catch (error) {
    console.error({ error });
    return {};
  }
};

export const getClubPassTemplates = async () => {
  try {
    const templateRef = db.collection(
      `clubs/${CLUB_ID}/config/v1/passtemplates`
    );
    const templates = await templateRef.get();
    const templateData = templates.docs
      .map((template) => ({
        id: template.id,
        ...template.data(),
      }))
      .sort((a, b) => {
        return a.order > b.order ? 1 : -1;
      });
    return templateData;
  } catch (error) {
    console.error({ error });
    return [];
  }
};

export const getOrder = async (orderId) => {
  try {
    const orderRef = db.collection(`clubs/${CLUB_ID}/orders`).doc(orderId);
    const order = await orderRef.get();
    return order.data();
  } catch (error) {
    console.error({ error });
    return {};
  }
};

export const getInvite = async (invitation) => {
  try {
    const inviteRef = db.collection(`clubs/${CLUB_ID}/invites`).doc(invitation);
    const invite = (await inviteRef.get()).data();
    return invite;
  } catch (error) {
    console.error({ error });
    return { error: true, message: "Invalid Invitation" };
  }
};

const validateEmail = (email) => {
  return !!email && email.includes("@") && email.length > 5;
};

export const sharePassesWithLink = async (passes, message) => {
  if (passes.length === 0) {
    return {
      error: true,
      message: "Please select at least one pass to share.",
    };
  }

  try {
    const shared = await httpsCallable(
      functions,
      "sharePassesWithLink"
    )({
      passes,
      message,
      clubId: CLUB_ID,
    });
    return shared;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const sharePass = async (passId, email) => {
  if (!validateEmail(email)) {
    return { error: true, message: "Please enter a valid email address" };
  }

  try {
    const shared = await httpsCallable(
      functions,
      "sharePass"
    )({
      passId,
      email,
      clubId: CLUB_ID,
    });
    return shared;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};
export const claimPass = async (inviteId) => {
  try {
    const claimed = await httpsCallable(
      functions,
      "claimPass"
    )({
      inviteId,
      clubId: CLUB_ID,
    });
    return claimed;
  } catch (error) {
    console.error({ error });
    return { error: true, message: error.message };
  }
};

export const checkin = async (passId) => {
  try {
    const checkedIn = await httpsCallable(
      functions,
      "createCheckin"
    )({
      passId,
      clubId: CLUB_ID,
    });
    return checkedIn;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const createBudPass = async () => {
  const localStorageKey = "budPassCreation";
  try {
    // Check local storage for a cached response
    const cachedData = localStorage.getItem(localStorageKey);
    if (cachedData) {
      const { created, timestamp } = JSON.parse(cachedData);

      // Check if the cached response is from today
      const cachedDate = new Date(timestamp);
      const currentDate = new Date();
      if (cachedDate.toDateString() === currentDate.toDateString()) {
        return created; // Return cached response if it's from today
      }
    }

    // Create a new bud pass if no valid cached response
    const created = await httpsCallable(
      functions,
      "createBudPass"
    )({
      clubId: CLUB_ID,
    });

    // Cache the new response
    const cacheData = {
      created,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(localStorageKey, JSON.stringify(cacheData));

    return created;
  } catch (error) {
    console.error({ error });
    return { error: true, ...error };
  }
};

export const signOut = () => {
  auth.signOut();
};

export const centsToCurrency = (cents) => {
  return (parseInt(cents, 10) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
