import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import { Products } from "../components/Products";
import { Passes } from "../components/Passes";
import { DefaultHeader } from "../components/header/DefaultHeader";

const Home = () => {
  return (
    <Container spacing={2}>
      <Box sx={{ my: 2 }}>
        <DefaultHeader />
      </Box>

      <Paper elevation={6} sx={{ p: 2, mt: 2, mb: 2 }}>
        <Passes />
      </Paper>
      <Paper elevation={6} sx={{ p: 2, mb: 2 }}>
        <Products />
      </Paper>
    </Container>
  );
};

export default Home;
