import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Paper, Typography, Box } from "@mui/material";
import { AppContext } from "../context/AppContext";
import { getInvite, claimPass } from "../services/firebase";
import { Loading } from "../components/Loading";
import { LoginToClaim } from "../components/invite/LoginToClaim";
import { InvalidInviteCode } from "../components/invite/InvalidInviteCode";
import { Claim } from "../components/invite/Claim";

export const Invite = () => {
  const { invitation } = useParams();
  const { isAuthPending, member } = useContext(AppContext);
  const [validating, setValidating] = useState(true);
  const [validInvite, setValidInvite] = useState(false);
  const [invite, setInvite] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateInvite = async (invite) => {
      if (invite && !invite.error) {
        setValidInvite(true);
        setInvite(invite);
      }
      setValidating(false);
    };
    getInvite(invitation).then(validateInvite);
  }, [invitation]);

  useEffect(() => {
    if (!isAuthPending && !member) {
      localStorage.setItem("redirectAfterLogin", `/invite/${invitation}`);
    }
  }, [member, invitation, isAuthPending]);

  const handleClaimPass = async () => {
    setValidating(true);
    const claimed = await claimPass(invitation);
    if (claimed && !claimed.error) {
      localStorage.removeItem("redirectAfterLogin");
      navigate("/");
    }
    if (claimed.error) {
      console.log({ claimed });
      setError(claimed.message);
    }
    setValidating(false);
  };

  return (
    <Paper elevation={12} sx={{ mt: 4, p: 4, minHeight: "80vh" }}>
      <Loading
        isLoading={isAuthPending || validating}
        messages={[
          "Validating Invitation",
          "Searching the archives...",
          "Investigating a mystery...",
          "Sorry, I got distracted...",
          "Found it!",
        ]}
      />
      {error && (
        <Box
          sx={{
            color: "red",
          }}
        >
          <Typography variant="h5" color="error">
            Uh, oh! Something went wrong
          </Typography>
          <Typography variant="h6">{error}</Typography>
        </Box>
      )}
      {!validInvite ?? (
        <Box>
          <Typography>Invalid Invite Code</Typography>
          <InvalidInviteCode />
        </Box>
      )}
      {validInvite && !member && <LoginToClaim invite={invite} />}
      {validInvite && member && (
        <Claim handleClaimPass={handleClaimPass} invite={invite} />
      )}
    </Paper>
  );
};
export default Invite;
