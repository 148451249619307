import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Container, Typography, Box } from "@mui/material";
import { auth } from "../services/firebase";
import { useNavigate } from "react-router-dom";

const SignInScreen = () => {
  const navigate = useNavigate();
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
        if (redirectAfterLogin) {
          localStorage.removeItem("redirectAfterLogin");
          navigate(redirectAfterLogin);
          return;
        }
        navigate("/demo");
      },
    },
    tosUrl: "/terms",
    privacyPolicyUrl: "/privacy",
  };

  return (
    <Container>
      <Box textAlign={"center"}>
        <img src="/logo.svg" alt="club logo" style={{ height: "30vh" }} />
      </Box>
      <Typography variant="h6" align="center">
        Log In or Create an Account
      </Typography>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </Container>
  );
};

export default SignInScreen;
